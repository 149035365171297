import {cva} from 'class-variance-authority';
import type {PropsWithChildren} from 'react';

import type {Link} from './types';

const styles = cva('py-3 px-4 rounded-lg', {
  variants: {
    isActive: {
      true: '',
    },
    mode: {
      light: '',
      dark: '',
    },
  },
  compoundVariants: [
    {
      isActive: true,
      mode: 'light',
      className: 'bg-shade-10',
    },
    {
      isActive: true,
      mode: 'dark',
      className: 'bg-shade-80',
    },
  ],
});

export default function Tier2Link({
  children,
  isActive,
  mode,
}: PropsWithChildren<Link>) {
  return (
    <li
      className={styles({
        isActive,
        mode,
      })}
    >
      {children}
    </li>
  );
}

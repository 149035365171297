import {useCallback} from 'react';
import cn from 'classnames';
import {useViewability} from '@shopify/dux';

import {ComplianceZone, ButtonSizeEnum} from '@/enums';
import {
  useBannerVariant,
  useCookieNoticeBanner,
} from '@/hooks/useCookieNoticeBanner';
import Section from '@/components/brochureV2/primitive/Section/Section';
import useDataLayer from '@/hooks/useDataLayer';
import {useTranslations} from '@/hooks/useTranslations';
import {useSiteData} from '@/hooks/useSiteData';
import FormButton from '@/components/base/elements/FormButton/FormButton';

interface CookieNoticeBannerProps {
  rootDomain: string;
  countryCode: string;
  storybookComplianceZone?: ComplianceZone;
}

/**
 * @deprecated
 */
export default function CookieNoticeBanner({
  rootDomain,
  countryCode,
  storybookComplianceZone,
}: CookieNoticeBannerProps) {
  const {t} = useTranslations();
  const {canonicalUrl} = useSiteData();
  const variant = useBannerVariant();
  const dataLayer = useDataLayer();
  const [viewableRef] = useViewability();
  const defaultComplianceZone =
    storybookComplianceZone || dataLayer.complianceZone || ComplianceZone.None;
  const {
    shouldShowCookieNotice,
    persistPassiveConsent,
    persistPrivacySignal,
    isActiveConsentVariant,
  } = useCookieNoticeBanner({
    rootDomain,
    url: canonicalUrl as string,
    complianceZone: defaultComplianceZone,
    countryCode,
    variant,
  });

  const handleClick = useCallback(
    (hasConsented: boolean) => {
      if (isActiveConsentVariant) {
        persistPrivacySignal(hasConsented);
      } else {
        persistPassiveConsent();
      }
    },
    [persistPassiveConsent, persistPrivacySignal, isActiveConsentVariant],
  );

  const Banner = () => (
    <Section
      className={cn(
        'cookie-notice-banner',
        'fixed bottom-0 left-0 z-50 flex w-full items-center justify-center bg-black py-6 text-white',
        'px-[6%] md:px-[20%] border-t border-t-white/20 pointer-events-auto',
      )}
      sectionName={`cookie-${
        isActiveConsentVariant ? 'active' : 'passive'
      }-consent-notice`}
      ref={viewableRef}
    >
      <div className="max-w-none xs:max-w-lg sm:max-w-2xl md:max-w-4xl lg:max-w-6xl flex">
        <p className="flex items-center pr-4 m-0">
          <span
            className={cn(
              'inline-block',
              'text-[0.8em] text-justify',
              // sub-links
              '[&>a]:text-shade-20',
              '[&>a]:underline [&>a]:inline-block',
              '[&>a:after]:w-[7px] [&>a:after]:h-[7px] [&>a:after]:inline-block [&>a:after]:ml-[2px] [&>a:after]:-mt-[22px] [&>a:after]:mr-1 [&>a:after]:align-middle',
              '[&>a]:after:content-external-indicator',
            )}
            dangerouslySetInnerHTML={{
              __html: isActiveConsentVariant
                ? t('global:cookiesNotice.activeConsentContentHtml')
                : t('global:cookiesNotice.contentHtml'),
            }}
          />
        </p>
        {!isActiveConsentVariant && (
          <div className="flex flex-col justify-evenly">
            <FormButton
              onClick={() => handleClick(true)}
              mode="dark"
              componentName="consent"
              size={ButtonSizeEnum.Small}
            >
              {t('global:cookiesNotice.buttonText')}
            </FormButton>
          </div>
        )}
        {isActiveConsentVariant && (
          <div
            className={cn(
              'flex flex-col sm:flex-row gap-2 items-center justify-center',
            )}
          >
            <FormButton
              onClick={() => handleClick(false)}
              mode="dark"
              componentName="reject"
              size={ButtonSizeEnum.Small}
              className="whitespace-nowrap rounded-[30px]"
            >
              {t('global:cookiesNotice.rejectAllButtonText')}
            </FormButton>
            <FormButton
              onClick={() => handleClick(true)}
              mode="dark"
              componentName="consent"
              size={ButtonSizeEnum.Small}
              className="whitespace-nowrap rounded-[30px]"
            >
              {t('global:cookiesNotice.acceptAllButtonText')}
            </FormButton>
          </div>
        )}
      </div>
    </Section>
  );

  return shouldShowCookieNotice ? <Banner /> : null;
}

import type {ReactNode} from 'react';

import {twMerge} from '@/stylesheets/twMerge';

export enum Span {
  One = 'col-span-1',
  Two = 'col-span-2',
  Three = 'col-span-3',
  Four = 'col-span-4',
  Five = 'col-span-5',
  Six = 'col-span-6',
  Seven = 'col-span-7',
  Eight = 'col-span-8',
  Nine = 'col-span-9',
  Ten = 'col-span-10',
  Eleven = 'col-span-11',
  Twelve = 'col-span-12',
}

export enum Start {
  One = 'col-start-1',
  Two = 'col-start-2',
  Three = 'col-start-3',
  Four = 'col-start-4',
  Five = 'col-start-5',
  Six = 'col-start-6',
  Seven = 'col-start-7',
  Eight = 'col-start-8',
  Nine = 'col-start-9',
  Ten = 'col-start-10',
  Eleven = 'col-start-11',
  Twelve = 'col-start-12',
}

export enum Breakpoint {
  Default = 'default',
  Sm = 'sm',
  Md = 'md',
  Lg = 'lg',
  Xl = 'xl',
}

export interface ColProps {
  children?: ReactNode;
  className?: string;
  start?:
    | string
    | {
        [key in Breakpoint]?: Start;
      }
    | Start;
  span?:
    | {
        [key in Breakpoint]?: Span;
      }
    | Span;
}

function getClassByProp(prop: ColProps['span'] | ColProps['start']) {
  return typeof prop === 'object'
    ? Object.entries(prop)
        .map(([key, value]) =>
          key === Breakpoint.Default ? value : `${key}:${value}`,
        )
        .join(' ')
    : prop;
}

export default function Col({
  children,
  className,
  start,
  span = Span.Twelve,
  ...props
}: ColProps) {
  const startClass = getClassByProp(start);
  const spanClass = getClassByProp(span);

  return (
    <div className={twMerge(spanClass, startClass, className)} {...props}>
      {children}
    </div>
  );
}

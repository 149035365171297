import {cva} from 'class-variance-authority';
import type {PropsWithChildren} from 'react';

const styles = cva('relative flex flex-col items-center h-full', {
  variants: {
    mode: {
      light: 'border-black/30',
      dark: 'border-white/30',
    },
  },
});

export default function PageTitleWrapper({
  children,
  mode,
}: PropsWithChildren<{mode: 'light' | 'dark'}>) {
  return <li className={styles({mode})}>{children}</li>;
}

import {cva} from 'class-variance-authority';
import type {PropsWithChildren} from 'react';

import {twMerge} from '@/stylesheets/twMerge';

const styles = cva('', {
  variants: {
    showGlobals: {
      true: 'hidden lg:flex',
      false: 'hidden',
    },
  },
});

interface GlobalNavWrapperProps {
  className?: string;
  showGlobals: boolean;
}

export function GlobalNavWrapper({
  children,
  className,
  showGlobals,
}: PropsWithChildren<GlobalNavWrapperProps>) {
  return (
    <div className={twMerge(styles({showGlobals}), className)}>{children}</div>
  );
}

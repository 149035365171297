import {useEffect} from 'react';

export const usePreventBodyScroll = (state: boolean) => {
  useEffect(() => {
    if (!document.body || !document.body.classList) return;

    if (state) {
      document.body.classList.add('u-prevent-scroll', 'overflow-hidden');
      document.body.style.overflow = 'hidden';
    } else {
      document.body.classList.remove('u-prevent-scroll', 'overflow-hidden');
      document.body.style.removeProperty('overflow');
    }

    return () => {
      if (!document.body || !document.body.classList) return;
      document.body.classList.remove('u-prevent-scroll', 'overflow-hidden');
      document.body.style.removeProperty('overflow');
    };
  }, [state]);
};

import {useCallback, useEffect, useState} from 'react';

import {useKeyPress} from '@/hooks/useKeyPress';
import {useMutationObserver} from '@/hooks/useMutationObserver';

export const FOCUSABLE_TAGS =
  'a, button, textarea, input, select, [tabindex], [contenteditable], iframe';

export const useFocusTrap = (
  ref: any,
  isActive = false,
  query = FOCUSABLE_TAGS,
) => {
  const [focusableElements, setFocusableElements] = useState<HTMLElement[]>([]);
  const resetFocusableElements = useCallback(() => {
    if (ref.current) {
      setFocusableElements(
        [...ref.current.querySelectorAll(query)].filter(
          (el: HTMLElement) =>
            !el.hasAttribute('tabindex') ||
            el.getAttribute('tabindex') !== '-1',
        ),
      );
    }
  }, [ref, query]);

  useEffect(() => {
    resetFocusableElements();
  }, [ref, isActive, resetFocusableElements]);

  useMutationObserver(
    ref,
    () => {
      resetFocusableElements();
    },
    {childList: true, subtree: true},
  );

  const firstElement = focusableElements[0];
  const lastElement = focusableElements[focusableElements.length - 1];

  const updateFocus = (event: KeyboardEvent) => {
    // Check if tabbing or shift + tabbing
    if (event.shiftKey) {
      // Only override default behaviour if the first element is active
      // This will loop us back to the end and focus last element
      if (document.activeElement === firstElement) {
        event.preventDefault();
        lastElement.focus();
      }
      // Only override default behaviour if the last element is active
      // This will loop us back to the start and focus first element
    } else if (document.activeElement === lastElement) {
      event.preventDefault();
      const focusableChildElements = [
        ...lastElement.querySelectorAll(query),
      ].filter(
        (el: Element) =>
          !el.hasAttribute('tabindex') || el.getAttribute('tabindex') !== '-1',
      );

      if (focusableChildElements.length > 0) {
        (focusableChildElements[0] as HTMLElement).focus();
      } else {
        firstElement.focus();
      }
    }
  };

  useKeyPress('Tab', (evt) => isActive && updateFocus(evt));

  return focusableElements;
};

import type {MutableRefObject} from 'react';
import {useEffect} from 'react';

export const useMutationObserver = (
  refsToObserve: MutableRefObject<HTMLElement[] | HTMLElement | null>,
  callback: MutationCallback,
  options: MutationObserverInit | (() => MutationObserverInit),
) => {
  useEffect(() => {
    if (!refsToObserve.current) {
      return;
    }

    const returnedOptions = typeof options === 'function' ? options() : options;
    const observer = new MutationObserver(callback);

    if (Array.isArray(refsToObserve.current)) {
      const returnedRefs = refsToObserve.current as HTMLElement[];
      returnedRefs.forEach((ref) => {
        if (ref) {
          observer.observe(ref, returnedOptions);
        }
      });
    } else if (refsToObserve.current) {
      observer.observe(refsToObserve.current, returnedOptions);
    }

    return () => {
      observer.disconnect();
    };
  }, [refsToObserve, callback, options]);
};

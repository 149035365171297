import {cva} from 'class-variance-authority';
import type {PropsWithChildren} from 'react';

const styles = cva(
  'absolute top-[-1px] bottom-0 left-1/2 w-[256px] p-4 px-0 box-content',
  {
    variants: {
      isActive: {
        true: 'visible',
        false: 'invisible',
      },
    },
  },
);

export default function FlyoutWrapper({
  children,
  id,
  isActive,
}: PropsWithChildren<{id: string; isActive: boolean}>) {
  return (
    <div className={styles({isActive})} id={id}>
      {children}
    </div>
  );
}

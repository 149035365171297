import {type VariantProps} from 'class-variance-authority';
import type {AriaRole} from 'react';

import {twMerge} from '@/stylesheets/twMerge';
import type {Mode} from '@/components/base/types';
import {buttonStyles} from '@/components/base/elements/Button/styles';

export interface ButtonProps extends VariantProps<typeof buttonStyles> {
  children: React.ReactNode;
  componentName?: string;
  intent?: 'primary' | 'secondary';
  mode?: Mode;
  onClick?: () => void;
  size?: 'small' | 'large';
  type?: 'reset' | 'submit';
  className?: string;
  role?: AriaRole;
}

export default function FormButton({
  children,
  componentName = 'form-button',
  onClick,
  intent = 'primary',
  mode,
  size,
  type = 'submit',
  className,
  ...props
}: ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>) {
  return (
    <button
      type={type}
      onClick={onClick}
      className={twMerge(
        buttonStyles({intent, mode, size, border: true}),
        className,
      )}
      data-component-name={componentName}
      data-mode={mode}
      {...props}
    >
      {children}
    </button>
  );
}

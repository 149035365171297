import {ButtonSizeEnum} from '@/enums';
import Button from '@/components/base/elements/Button/Button';
import type {Mode} from '@/components/base/types';

export default function SkipLink({mode, text}: {mode: Mode; text: string}) {
  return (
    <div className="absolute top-2 left-2 z-[100] pointer-events-none">
      <Button
        href="#main"
        size={ButtonSizeEnum.Small}
        intent="primary"
        mode={mode}
        // We use custom top/left here instead of sr-only, since sr-only applies
        // additional attributes that mess with button styling (i.e. padding).
        // We also want to be sure the skip link is _always_ on top of any other component when in focus,
        // sighted users can see that it has focus.
        className="transition-transform duration-300 translate-y-[-200%] motion-reduce:transition-none focus:translate-y-0"
      >
        {text}
      </Button>
    </div>
  );
}

import type {ReactElement} from 'react';
import {useEffect, useState} from 'react';
import cn from 'classnames';

import {ApprovedCookie} from '@/enums';
import {getCookie} from '@/utils/utils';

import type {ColProps} from './Col';

interface GridProps {
  children: ReactElement<ColProps> | ReactElement<ColProps>[] | any;
  className?: string;
  style?: React.CSSProperties;
}

/**
 * @deprecated Use `base/layouts/utils/Grid` instead
 */
export default function GridClient({children, className, style}: GridProps) {
  const [devMode, setDevMode] = useState(false);
  useEffect(() => {
    const newDevMode = getCookie(ApprovedCookie.EnableDevmode) === 'true';
    setDevMode(newDevMode);
  }, []);
  return (
    <div
      className={cn(
        'container grid grid-cols-4 sm:grid-cols-8 md:grid-cols-12 gap-x-gutter gap-y-0',
        {
          'bg-grid__devMode-4 sm:bg-grid__devMode-8 md:bg-grid__devMode-12-md lg:bg-grid__devMode-12-lg':
            devMode,
        },
        className,
      )}
      style={style}
    >
      {children}
    </div>
  );
}

import cn from 'classnames';

import {HeaderBackgroundColorScheme} from '@/enums';

interface CaretProps {
  background?: HeaderBackgroundColorScheme;
  isActive: boolean;
}

export default function Caret({isActive, background}: CaretProps) {
  const lineStyles = cn(
    'inline-block rounded border-b-8 h-2 w-[2px] origin-center',
    'transition-all ease-[bezier(0.66, 0.66, 0.34, 1.00)] duration-[350ms] motion-reduce:transition-none',
    {
      'border-white bg-white':
        background === HeaderBackgroundColorScheme.Black ||
        background === HeaderBackgroundColorScheme.TransparentDark,
    },
    {
      'border-black bg-black':
        background !== HeaderBackgroundColorScheme.Black &&
        background !== HeaderBackgroundColorScheme.TransparentDark,
    },
  );

  return (
    <div
      aria-hidden
      className="relative inline-block h-[7px] w-3 cursor-pointer"
    >
      <div
        className={cn(
          lineStyles,
          '-translate-x-[3px]',
          {'rotate-[135deg]': !isActive},
          {'rotate-45': isActive},
        )}
      />
      <div
        className={cn(
          lineStyles,
          {'-rotate-[135deg]': !isActive},
          {'-rotate-45': isActive},
        )}
      />
    </div>
  );
}

import {cva} from 'class-variance-authority';
import type {PropsWithChildren} from 'react';

const styles = cva('flex flex-col gap-y-6 pl-6 border-l border-shade-30', {
  variants: {
    isActive: {
      true: 'block',
      false: 'hidden',
    },
  },
});

export default function MobileListStyles({
  children,
  id,
  isActive,
}: PropsWithChildren<{id: string; isActive: boolean}>) {
  return (
    <ul className={styles({isActive})} data-active={isActive} id={id}>
      {children}
    </ul>
  );
}

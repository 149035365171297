import {cva} from 'class-variance-authority';
import type {PropsWithChildren} from 'react';
import {useRef} from 'react';

import {FOCUSABLE_TAGS, useFocusTrap} from '@/hooks/useFocusTrap';
import {useKeyPress} from '@/hooks/useKeyPress';
import {twMerge} from '@/stylesheets/twMerge';

const styles = cva(
  'w-[224px] h-full px-4 border-l opacity-0 transition-opacity delay-150 box-content',
  {
    variants: {
      isActive: {
        true: 'opacity-100',
      },
      mode: {
        light: 'border-shade-30',
        dark: 'border-shade-60',
      },
    },
  },
);

interface FlyoutListProps {
  closeTier2: () => void;
  isActive: boolean;
  mode?: 'light' | 'dark';
}

export default function FlyoutList({
  children,
  closeTier2,
  isActive,
  mode,
}: PropsWithChildren<FlyoutListProps>) {
  const ref = useRef<HTMLUListElement>(null);

  const query = FOCUSABLE_TAGS.split(', ')
    .map((tag) => `:scope > li > ${tag}`)
    .join(', ');
  useFocusTrap(ref, isActive, query);
  useKeyPress('Escape', () => {
    closeTier2();
  });

  return (
    <ul
      ref={ref}
      className={twMerge(
        styles({
          isActive,
          mode,
        }),
      )}
    >
      {children}
    </ul>
  );
}

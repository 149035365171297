import {Span, Start} from '@/components/brochureV2/layout/Grid/Col';
import {type Site} from '@data/types';

export function getSpanByColumnSpan(columnSpan: number): Span {
  return Object.values(Span)[columnSpan - 1];
}

export function getStartByColumnStart(columnStart: number): Start {
  return Object.values(Start)[columnStart - 1];
}

export function getEditionsUrlBySite(site: Site, path = ''): string {
  if (site.features.includes('editions')) return path;
  return '/editions';
}

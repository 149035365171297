import {cva} from 'class-variance-authority';
import type {PropsWithChildren} from 'react';

import type {Link} from './types';

const styles = cva(
  'relative flex flex-col items-center h-full after:absolute after:w-full after:h-[3px] after:bottom-0 hover:underline',
  {
    variants: {
      isActive: {
        true: 'after:block',
        false: 'after:hidden',
      },
      mode: {
        light: 'after:bg-black',
        dark: 'after:bg-white',
      },
    },
  },
);

export default function Tier1Link({
  children,
  isActive,
  mode,
}: PropsWithChildren<Link>) {
  return (
    <li
      className={styles({
        isActive,
        mode,
      })}
    >
      {children}
    </li>
  );
}

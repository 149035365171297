import {useCallback, useEffect, useState} from 'react';

export const useScrollThresholds = (thresholds: number[]) => {
  const [scrolledThreshold, setScrolledThreshold] = useState(0);

  const handleScroll = useCallback(() => {
    const scrollPosition = window.scrollY;
    const highestThreshold = thresholds
      .sort((a, b) => b - a)
      .find((threshold) => scrollPosition >= threshold);

    setScrolledThreshold(highestThreshold || 0);
  }, [thresholds]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, {passive: true});
    window.addEventListener('touchmove', handleScroll, {passive: true});

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('touchmove', handleScroll);
    };
  }, [thresholds, handleScroll]);

  return scrolledThreshold;
};

import cn from 'classnames';
import type {MouseEventHandler} from 'react';
import {useRef} from 'react';

import {HeaderBackgroundColorScheme} from '@/enums';
import {useKeyPress} from '@/hooks/useKeyPress';
import {useTranslations} from '@/hooks/useTranslations';

interface HamburgerProps {
  background?: HeaderBackgroundColorScheme;
  isActive: boolean;
  onToggle: MouseEventHandler<HTMLButtonElement>;
}

export default function Hamburger({
  background,
  isActive,
  onToggle,
}: HamburgerProps) {
  const {t} = useTranslations();
  const hamburgerRef = useRef<HTMLButtonElement>(null);

  useKeyPress('Escape', () => isActive && hamburgerRef?.current?.focus());

  const barStyles = cn(
    'h-0.5 w-[1.125rem]',
    'transition-all ease-out-in duration-500 motion-reduce:transition-none',
    {
      'bg-white':
        background === HeaderBackgroundColorScheme.Black ||
        background === HeaderBackgroundColorScheme.TransparentDark,
      'bg-black':
        background !== HeaderBackgroundColorScheme.Black &&
        background !== HeaderBackgroundColorScheme.TransparentDark,
    },
  );

  return (
    <button
      ref={hamburgerRef}
      className="flex h-12 w-12 cursor-pointer select-none flex-col items-center justify-center gap-1"
      aria-label={t('global:ariaLabels.header.mobileNavMenu')}
      aria-expanded={isActive}
      aria-controls="MobileDrawer"
      aria-haspopup="true"
      type="button"
      onClick={onToggle}
      data-component-name={`navigation-toggle-${isActive ? 'close' : 'open'}`}
    >
      <div
        className={cn(barStyles, {
          'origin-top-left rotate-45 translate-x-1': isActive,
        })}
      />
      <div className={cn(barStyles, {'opacity-0': isActive})} />
      <div
        className={cn(barStyles, {
          'origin-bottom-left rotate-[-45deg] translate-x-1': isActive,
        })}
      />
    </button>
  );
}
